import { Alert, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { IChildrenFieldOptions, IForm } from "../../../../context/IForm";
import { Form } from "../../../Form";
import { DataViewComponent } from "../../DataViewComponent/DataViewComponent";
import { FormBody } from "../../FormBody/FormBody";
import { Loading } from "../../Loading";
import { FieldProps } from "../FieldProps";
import { DiscriminatedObjectForm } from "../ObjectField/DiscriminatedObjectForm";
import { Default } from "../../../../context/AppContext";
import { observable } from "mobx";
import * as React from "react";
import { Dump } from "../../../../Dump";

interface IProps extends FieldProps {
    form?: Form;
}

export function ChildrenField(props: IProps) {
    const { field, form, value } = props;
    const { isReadOnly: fieldReadOnly } = field;
    const { isReadOnly: formReadOnly } = form ?? {};
    const isReadOnly = formReadOnly || fieldReadOnly;

    const [formComponents, setFormComponents] = useState<{ [key: string]: Form }>();
    const [error, setError] = useState<string>();
    const [url, setUrl] = useState<string>();
    const [selectedKey, setSelectedKey] = useState<string>();
    const [isAdding, setAdding] = useState<boolean>(false);

    const options: IChildrenFieldOptions = field.options as IChildrenFieldOptions;
    const { editForms, addFormUrls, keyType } = options;

    const isArray = keyType === "int";

    // TODO: depends on the key type (for now only supports array)
    // ...
    const newKey = `${formComponents ? Object.keys(formComponents).length : 0}`;
    const newChildContext = `${form?.name}.${field.name}.${newKey}`;

    useEffect(() => {
        if (!isReadOnly) {
            return;
        }

        const values = form?.getValues();
        if (!values) {
            setError("Missing form");
            return;
        }

        if (!options.url) {
            // setError(`Missing url: ${JSON.stringify(values)}`);
            return;
        }

        let id: string | undefined;
        if ("_id" in values) {
            id = values["_id"] as string;
        } else if ("Id" in values) {
            id = values["Id"] as string;
        } else if ("id" in values) {
            id = values["id"] as string;
        }

        const url = options.url.indexOf("{{id}}") < 0 ? options.url : options.url.replace("{{id}}", !!id ? id : "MISSING_ID");
        setUrl(url);
    }, [form, options.url, isReadOnly]);

    useEffect(() => {
        if (isReadOnly || !editForms || !form) return;

        console.log("Create forms", editForms);

        // create forms
        const indices = Object.keys(editForms);
        const formComps: { [key: string]: Form } = {};
        indices.forEach((x, i) => {
            const config = editForms[x];
            if (!config.title) config.title = config.name;

            config.name = `${form.name}.${field.name}.${x}`;
            const formComp = Form.create(config as IForm, null);
            formComps[x] = formComp;
        });
        setFormComponents(formComps);
    }, [isReadOnly, editForms, field.name, form]);

    useEffect(() => {
        if (!isAdding || !form?.name || !isArray) return;

        // init parent container with an observable array
        const parent = `${form.name}.${field.name}`;
        if (!Form.getValues(parent)) {
            Default.state.set(parent, observable([]), "form");
        }

    }, [isAdding]);

    const onFinishAdding = () => {
        if (!isAdding) return;

        const lastForm = Form.get(newChildContext);
        if (lastForm) {
            const newFormComponents = {
                ...formComponents
            };
            newFormComponents[newKey] = lastForm;
            setFormComponents(newFormComponents);
            setSelectedKey(newKey);
        }
    };

    const onChange = (event: SelectChangeEvent<string>) => {
        onFinishAdding();
        setAdding(false);
        setSelectedKey(event.target.value);
    };

    const onNew = () => {
        onFinishAdding();
        setSelectedKey("[NEW]");
        setAdding(true);
    };

    if (!form) {
        return <Alert severity="error">Missing form</Alert>;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (isReadOnly) {
        if (!options.url) {
            return (
                <div>
                    <Dump
                        style={{
                            margin: 0,
                            padding: 0,
                            fontSize: "10pt",
                            color: "gray"
                        }}
                        object={value}
                    />
                </div>
            );
        }

        if (!url) {
            return <Loading />;
        }

        const { style } = field;
        const computedStyle = style || {
            border: "1px solid rgba(0,0,0,.26)",
            // backgroundColor: disabled ? "rgb(239, 239, 239)" : "white",
            // borderRadius: 8,
            height: "100%",
            position: "relative"
        };

        return (
            <div
                style={{
                    ...computedStyle,
                    overflow: "hidden"
                }}
            >
                <DataViewComponent view={url} overriddenTitle={field.label ?? field.name!} />
            </div>
        );
    }

    const selectedForm = !!formComponents && selectedKey ? formComponents[selectedKey] : undefined;

    return (
        <>
            <div style={{
                paddingTop: "12px",
                paddingBottom: "4px",
                paddingRight: "4px",
                width: "100%",
                display: "flex"
            }}>
                {(isAdding || (formComponents && Object.keys(formComponents).length > 0)) && (
                    <FormControl variant="filled" sx={{ minWidth: "50%" }}>
                        <InputLabel>{field.label ?? field.name}</InputLabel>
                        <Select
                            value={selectedKey ?? ""}
                            onChange={onChange}
                            variant="filled"
                            sx={{ background: "transparent" }}
                        >
                            {
                                formComponents && Object.keys(formComponents).map(((x, i) => (
                                    <MenuItem value={x} key={x}>
                                        {isArray ? `#${i + 1}` : x}
                                    </MenuItem>
                                )))
                            }
                            {isAdding && (
                                <MenuItem value="[NEW]" key="[NEW]">
                                    New
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                )}
                {addFormUrls && <Button onClick={onNew}>New</Button>}
            </div>

            {isAdding && (
                <DiscriminatedObjectForm {...options} context={newChildContext} form={form} />
            )}

            {
                formComponents && Object.keys(formComponents).map((x, i) => (
                    <div key={x} style={{
                        backgroundColor: "white",
                        paddingRight: 4,
                        display: x === selectedKey ? "block" : "none"
                    }}>
                        <FormBody form={formComponents[x]} key={x} />
                    </div>

                ))
            }
        </>
    );
}

