import { IObjectFieldOptions } from "../../../../context/IForm";
import { useState } from "react";
import { Default } from "../../../../context/AppContext";
import { DiscriminatedObjectForm } from "./DiscriminatedObjectForm";
import { Button } from "@mui/material";
import { IObjectFieldProps } from "./index";

export function StandardObjectField(props: IObjectFieldProps) {
    const { field, value, form, onChange } = props;
    const options = field.options as IObjectFieldOptions;
    const { isRequired } = field;

    const readonly = field.isReadOnly || form.isReadOnly || props.readonly;
    const [isEditing, setEditing] = useState<boolean>(!readonly && (!!value || !!isRequired));
    const context = `${props.form.name}.${field.name}`;

    const onEdit = () => {
        setEditing(true);
    };

    const onClear = () => {
        if (onChange) {
            onChange(field, null);
        } else {
            Default.state.set(context, null);
        }

        setEditing(false);
    };

    if (readonly) {
        if (!value) return <span>&nbsp;</span>;

        return (
            <DiscriminatedObjectForm {...options} context={context} readonly value={value} form={form} />
        );
    }

    if (isEditing) return (
        <>
            <DiscriminatedObjectForm {...options} context={context} form={form}/>
            {!isRequired && <Button onClick={onClear}>Clear</Button>}
        </>
    );

    return (
        <Button onClick={onEdit}>New</Button>
    );
}