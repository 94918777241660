import { Alert, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { IForm } from "src/pi/context/IForm";
import DataService from "src/pi/services/DataService";
import { Form } from "../../../Form";
import { FormBody } from "../../FormBody/FormBody";
import { Loading } from "../../Loading";
import { UnknownValue } from "../../../ObjectTypeDesigner/UnknownValue";

interface IDiscriminatedObjectFormProps {
    editForm?: IForm|null;
    addFormUrls?: { [key: string]: string|null }|null;
    context: string;
    readonly?: boolean;
    value?: object;
    objectType?: string | null;
    form?: Form;
}

export function DiscriminatedObjectForm(props: IDiscriminatedObjectFormProps) {
    const { editForm, addFormUrls, context, readonly, value, objectType, form } = props;
    const [currForm, setCurrForm] = useState<Form>();
    const [selectedObjectType, setSelectedObjectType] = useState<string>();
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState<boolean>();

    useEffect(() => {
        if (editForm) {
            editForm.name = context;
            if (readonly) editForm.isReadOnly = true;

            setCurrForm(Form.create(editForm, null));
            return;
        }

        if (readonly) return;

        // if (!addFormUrls) {
        //     setError("Missing add forms");
        //     return;
        // }

        setCurrForm(undefined);

        if (!selectedObjectType) {
            if (objectType && (!addFormUrls || !!addFormUrls[objectType]) ) {
                setSelectedObjectType(objectType);
                return;
            }

            const types = addFormUrls ? Object.keys(addFormUrls) : undefined;
            if (types && types.length === 1) {
                console.log('load form for only object', types[0]);
                setSelectedObjectType(types[0]);
            }
            return;
        }

        let url = addFormUrls?.[selectedObjectType] ?? selectedObjectType;

        if (!url) {
            setError(`Missing url for ${selectedObjectType}`);
            return;
        }

        if (!url.startsWith('/')) {
            url = `/api/v1/${url}`;
        } else if (form) {
            url = form.replacePlaceHolders(url) ?? url;
        }

        setLoading(true);
        DataService().dataFormAsync(url)
            .then(form => {
                if (!form) {
                    setCurrForm(undefined);
                    setError('Failed to load form');
                    setLoading(false);
                    return;
                }

                form.name = context;
                setCurrForm(Form.create(form, null));
                setLoading(false);
            });
    }, [editForm, addFormUrls, context, selectedObjectType, readonly]);

    const onChange = (event: SelectChangeEvent<string>) => {
        setSelectedObjectType(event.target.value);
    };

    if (error) return <Alert severity="error">{error}</Alert>;
    if (isLoading) return <Loading />;

    // console.log('form', currForm);

    if (readonly) {
        if (currForm) {
            return <FormBody form={currForm} />
        }

        return <UnknownValue value={value}/>
    }

    return (
        <>
            {addFormUrls && Object.keys(addFormUrls).length > 1 && (
                <div style={{ paddingTop: '12px', paddingBottom: '4px', paddingRight: '4px', width: '100%' }}>
                    <Select
                        value={selectedObjectType ?? ""}
                        onChange={onChange}
                        label="Type"
                        sx={{ height: 45, width: '100%', display: "flex" }}
                        variant="filled"
                    >
                        {Object.keys(addFormUrls).map(x => (
                            <MenuItem value={x} key={x}>
                                {x}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            {currForm && <FormBody form={currForm} />}
        </>
    );
}
